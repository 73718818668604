import React from 'react'

export function leftRightSection(def) {
  let imageWrapCss = 'pl-4 -mr-48 sm:pl-6 md:-mr-16'
  let imageCss = 'lg:left-0'
  let firstWrapCss = ''
  let secondWrapCss = ''

  if (def.position === 'left') {
    imageWrapCss = 'pr-4 -ml-48 sm:pr-6 md:-ml-16'
    imageCss = 'lg:right-0'
    firstWrapCss = 'lg:col-start-2'
    secondWrapCss = 'lg:col-start-1'
  }

  return (
    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
      <div className={`${firstWrapCss} px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0`}>
        <div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {def.header}
            </h2>

            {
              def.body.map((content) => (
                <p className="mt-4 text-lg text-gray-500">{content}</p>
              ))
            }
          </div>
        </div>
      </div>
      <div className={`${secondWrapCss} mt-12 sm:mt-16 lg:mt-0`}>
        <div className={`${imageWrapCss} lg:px-0 lg:m-0 lg:relative lg:h-full`}>
          {
            def.image && (
              <img
                className={`${imageCss} w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none`}
                src={def.image}
                alt={def.imageAlt}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}
