import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import featureImage from "../../static/images/design-thinking-1.svg"
import whatYouSeeImage from "../../static/images/What-You-See.png"
import whatTheySeeImage from "../../static/images/What-They-See.png"
import appAnalyticsImage from '../../static/images/App_Analytics_Pages.png'
import appSpaceImage from '../../static/images/App_Space_List.png'

import { leftRightSection } from "../components/leftRightSection"
import LetsTalk from "../components/LetsTalk"

const ProductPage = () => (
  <Layout>
    <Seo title="Product Overview | Clove" />

    <div className="pt-10 bg-white sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:pt-12">
              <h1 className="heading-hero sm:pb-5">
                <span>Your CSM team's big ideas.&nbsp;</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-400 to-brand-700">Realized.</span>
              </h1>

              <div className="max-w-prose space-y-2 text-primary leading-relaxed">
                <p>
                  Give the best onboarding, renewal, and learning experience to your customers.
                  Without stressing out your CSM team.
                </p>
              </div>
            </div>
          </div>
          <div className="my-12 lg:my-0">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <img
                className="max-w-full lg:pt-10 lg:w-9/12 mx-auto"
                src={featureImage}
                alt="Woman letting flying idea light-bulbs out of a box."
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 py-8 sm:py-16 lg:py-24">
      <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-base font-semibold tracking-wider text-brand-600 uppercase">
            How Clove Works
          </h2>
          <p className="mt-5 max-w-prose mx-auto text-primary">
            Clove connects your existing software and content into a Customer Hub. You define the
            process for your customers, and Clove delivers it to them. Clove is designed
            from the ground-up to be flexible to any company's needs.
          </p>
        </div>

        <div className="mt-24 grid md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-gray-700 mb-4 uppercase">
              What You See
            </h2>

            <p className="mb-4">
              Build success spaces with videos, embedded files, action plans, and more. Target the right
              users with segmentation and access control.
            </p>

            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src={whatYouSeeImage}
              alt="The back-end of Clove Success Spaces let you lay out the process for your customer."
            />
          </div>

          <div>
            <h2 className="text-base font-semibold tracking-wider text-gray-700 mb-4 uppercase">
              What Your Customers See
            </h2>

            <p className="mb-4">
              Whether one-to-one or one-to-many, your customers see the spaces and content that you've
              put together for them.
            </p>

            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src={whatTheySeeImage}
              alt="The finished Success Space as shown to your customers."
            />
          </div>
        </div>
      </div>
    </div>

    <div className="relative bg-white py-8 md:py-24 lg:py-40">
      {leftRightSection(IMPLEMENTATION_SECTION)}
    </div>

    <div className="relative bg-gray-50 py-8 md:py-24 lg:py-40">
      {leftRightSection(ANALYTICS_SECTION)}
    </div>

    <LetsTalk />
  </Layout>
)

const IMPLEMENTATION_SECTION = {
  header: (
    <div>
      <h2 className="text-base font-semibold tracking-wider text-brand-600 uppercase">
        How Clove Helps
      </h2>

      <p className="heading-primary">
        Drive engagement throughout your implementation process
      </p>
    </div>
  ),
  body: [
    `Modern products require modern implementation. Relevant and personal to the customer. Scalable and efficient for the implementation manager.`,
    `Clove lets you quickly setup processes used by segments of your customers, with the ability to customize as needed.`
  ],
  image: appSpaceImage,
  position: 'left'
}

const ANALYTICS_SECTION = {
  header: (
    <div>
      <h2 className="text-base font-semibold tracking-wider text-brand-600 uppercase">
        How Clove Tracks
      </h2>

      <p className="heading-primary">
        Your customers' engagement with your product and content
      </p>
    </div>
  ),
  body: [
    `Knowledge is the key to success, both for you and your customers. Clove's Analytics Suite reports on a customer's key moments and content engagement.`,
    (
      <span>
        <a class="underline" target="_blank" href="/blog/cs-tiers-of-data/">Not all data is created equal.</a>
        &nbsp;So, we provide access to raw data in addition to our reports.
      </span>
    )
  ],
  image: appAnalyticsImage,
  position: 'right'
}

export default ProductPage
